import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PageWithHeader from '../components/pageWithHeader';
import { findImageByContentId } from '../helpers/contentfulHelpers';
import style from './capability.module.scss';
import { SecondaryLinkButton } from '../components/buttons';
import HighlightRow from '../components/highlightRow';
import { RichTextOptions } from '../components/richTextOptions';

const Capability = ({ pageContext, path }) => {
  const { data, assets } = pageContext;
  let photo = findImageByContentId(assets, data.photo.contentful_id);
  
  if(data.headerImage) {
    photo = findImageByContentId(assets, data.headerImage.contentful_id);
  }

  return (
    <PageWithHeader title={data.name} url={path} image={photo.fluid.src} headerImage={photo.fluid.src}>
      <article>
        <div className={style.contentArea}>
          {documentToReactComponents(data.content.json, RichTextOptions(assets))}
        </div>
        {data.highlightTitle && 
          <HighlightRow
            title={data.highlightTitle}
            description={data.highlightDescription}
            image={data.highlightImage}
            buttonText={data.highlightButton}
            buttonLink={data.highlightLink}
          >
          </HighlightRow>
        }
      </article>
      {data.contactTitle && data.contactLink && data.contactButton &&
        <div className={style.contactLinkContainer}>
          <div className={style.contactTitle}>{data.contactTitle}</div>
          <div>
            <SecondaryLinkButton to={data.contactLink}>{data.contactButton}</SecondaryLinkButton>
          </div>
        </div>}
    </PageWithHeader>
  );
};

export default Capability;
